import LoaderModal from "components/common/Modal/LoaderModal";
import ModalComponent from "components/common/Modal/Modal";
import UserContext from "Context/UserProvider";
import { off } from "process";
import React, { useContext, useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Select from "react-select";

// const LoaderExampleInlineCentered = () => <Loader size='big' active inline='centered' />

function checking_null_undefined(array: any) {
    if (array === null || array === undefined) {
        return [];
    }
    return array;
}

function AddBusinessTabScreen(props: any) {
    const [toggle, setToggle] = useState(true);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Sorry there is some issue during creating a business, Please try again later");
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const categories = checking_null_undefined(props.categories);

    const [selectedCategory, setSelectedCategory] = useState({ label: "CHARGE_POINT_OPERATOR", value: "CHARGE_POINT_OPERATOR", name: "businessCategory" }) as any;
    const [fleetRequired, setFleetRequired] = useState<boolean | null>(null);

    let options = categories.map((e: any) => ({
        label: e.name,
        value: e.name,
        fleet: e.fleetRequired,
        name: "businessCategory",
    }));

    const initialChoice = options[0];
    const initialState = {
        businessCategory: initialChoice,
        fleetName: "",
    };

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const navigate = useNavigate();
    function toggleButton() {
        setToggle((prev) => !prev);
    }



    function onChange(e: any) {

        if (e.name === "businessCategory") {
            const selectedCategory = options.filter((i: any) => i.value === e.value)[0];
            setSelectedCategory(selectedCategory);

            const fleetOption = options.filter((i: any) => i.fleet === e.fleet)[0];
            setFleetRequired(fleetOption ? fleetOption.fleet : null);
        }
    }

    const registerBusiness = async () => {
        setLoading(true);

        // const validationStatus = validateForm()
        // if (!validationStatus) {
        //     return
        // }
        const businessInput = document.getElementById("business-name") as HTMLInputElement;
        const fleetInput = document.getElementById("fleet-name") as HTMLInputElement;

        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["accountUuid"] = userInfo.accountUuid;
        bodyData["businessName"] = businessInput.value;
        bodyData["fleetName"] = fleetInput?.value;
        bodyData["makeActiveOnceCreated"] = toggle;
        bodyData["selectedCategory"] = selectedCategory.value;
        // bodyData['survey'] = state
        // bodyData['surveyUuid'] = splitURL[3]
        let fetch_link = `${rootUrl}/business/create`;
        let fetch_option = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);
        if (response.status !== 200) {
            let res_json = await response.json();
            if (res_json.code === "BUSINESS_NAME_DUPLICATE") {
                setErrorMessage("You have create duplicate a business name, Please try with a new name.");
            } else if (res_json.code === "MANDATORY_DATA_MISSING") {
                setErrorMessage("You have not input the business name, Please fill and try again. ");
            }
            setError(true);
            openModal();
            setLoading(false);
            return;
        }
        let res_json = await response.json();

        if (fleetRequired) {
            if (toggle) {
                setUserInfo((prev: any) => ({ ...prev, businessName: businessInput.value, businessUuid: res_json.business.uuid, fleetUuid: res_json.business.fleets?.[0]?.uuid ?? null, currentFleetName: res_json.business.fleets?.[0]?.name ?? null }));
                navigate("/plan");
            }
            fleetInput.value = "";

        }
        businessInput.value = "";

        props.fetchBusinessInfo();
        setLoading(false);

        openModal();
    };

    return (
        <div
            className="col-xs-12 col-md-6 col-xl-4"
            style={{ width: "70%" }}>
            {loading && <LoaderModal />}

            <ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
                <>
                    <div
                        onClick={() => {
                            closeModal();
                        }}
                        style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IoMdClose size={30}
                        />
                    </div>
                    {isError ? (
                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center", margin: 10, padding: 50, justifyContent: "center", alignItems: "center", gap: 30 }}>
                            <h1>{errorMessage}</h1>
                        </div>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center", margin: 10, padding: 50, justifyContent: "center", alignItems: "center", gap: 30 }}>
                            <h1>Thank you for creating new business with us!</h1>
                        </div>
                    )}
                </>
            </ModalComponent>
            <div className="inputGroup">
                <input
                    type="text"
                    required={true}
                    autoComplete="off"
                    name="business-name"
                    id="business-name"
                />
                <label>Business Name</label>
            </div>
            <span>Choose Category:</span>
            <br />
            <br />

            <Select
                defaultValue={[initialChoice]}
                name="businessCategory"
                options={options}
                onChange={onChange}
            />
            <br />
            {fleetRequired ? (
                <div>
                    <br />
                    <div className="inputGroup">
                        <input
                            type="text"
                            required={true}
                            autoComplete="off"
                            name="fleet-name"
                            id="fleet-name"
                        />
                        <label>Fleet Name</label>
                    </div>
                    <br />

                    <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 5 }}>
                        <span>Mark as active</span>
                        <Switch
                            onChange={toggleButton}
                            checked={toggle}
                            name={"markActive"}
                        />
                    </label>
                </div>) : null}
            <br />
            <br />
            <button
                className="primary-btn"
                onClick={() => registerBusiness()}>
                Save
            </button>
        </div>
    );
}

export default AddBusinessTabScreen;
