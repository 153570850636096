import React, {Dispatch,SetStateAction} from 'react'

interface ImageToggle{
	img: string,
	showBigImage: boolean,
	setShowBigImage: Dispatch<SetStateAction<boolean>>
}

const ImageDisplay = (props:ImageToggle) => {
	return(
		<>
			<div className="expanded-image-backdrop"></div>
			<div className="exit-expanded-image" onClick={() => props.setShowBigImage(false)}>
				<svg className="overlay-cross">
					<line x1="10" y1="10" x2="40" y2="40" stroke="white" strokeWidth="5" strokeLinecap="round"/>
					<line x1="40" y1="10" x2="10" y2="40" stroke="white" strokeWidth="5" strokeLinecap="round"/>
				</svg>
			</div>
			<div style={{backgroundImage: `url(${props.img})`}} className="expanded-image-container"></div>
		</>
		)
}

export default ImageDisplay