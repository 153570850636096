import React, { InputHTMLAttributes, useContext, useEffect, useReducer, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import UserContext from "Context/UserProvider";
import Select from "react-select";
import WeeklyChargingReservation from "../../components/charging/BusinessChargingNetworkParameters/WeeklyChargerReservation";
import { WeekDay, weekDays as initialWeekDays } from "../../components/charging/BusinessChargingNetworkParameters/weekDays";
import { initialChargingPartnerRateCardValues } from "../../components/charging/BusinessChargingNetworkParameters/initialChargingPartnerRateCardValues";
import { toHourValues } from "../../components/charging/BusinessChargingNetworkParameters/toHourValues";
import { reservationOptions } from "components/charging/BusinessChargingNetworkParameters/reservationOptions";
import { isChargePointOperator } from "utils/productUtils";
import toast from 'react-hot-toast';
import PowerPurchaseAgreementQuotesTable from "components/charging/PowerPurchaseAgreementComponent/PowerPurchaseAgreementQuotesTable";
import { useNavigate } from "react-router-dom";
import LogoGrid from "components/charging/PowerPurchaseAgreementComponent/LogoGrid";
import Map from "../../components/common/MapComponent/Map";


interface OptionType {
    label: string;
    value: string;
    name: string;
}
function PowerPurchaseAgreementCreatePage(props: any) {

    const [loading, setLoading] = useState(true);
    const { userInfo, setUserInfo } = useContext(UserContext);
    const [parameter, setParameter] = useState({}) as any;
    const location = useLocation();
    const params = location.state as any;
    const currentInput = useRef() as any;
    const [isDisabled, setIsDisabled] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const [numberOfVehicles, setNumberOfVehicles] = useState<number | null>(null);
    const [chargesPerWeek, setChargesPerWeek] = useState<number | null>(null);
    const [averageChargeSize, setAverageChargeSize] = useState<number | null>(null);
    const [monthlyRequirementKWh, setMonthlyRequirementKWh] = useState<number | null>(null);

    const [quotes, setQuotes] = useState([]) as any

    useEffect(() => {
        // fetchChargingPartnerRateCard();
        setLoading(false);
    }, []);


    const calculateMonthlyRequirement = (vehicles: number | null, charges: number | null, chargeSize: number | null) => {
        if (vehicles !== null && charges !== null && chargeSize !== null) {
            const result = (vehicles * charges * chargeSize * 52) / 12;
            setMonthlyRequirementKWh(result);
        } else {
            setMonthlyRequirementKWh(0);
        }
    };

    const onChange = (e: any) => {
        const { name, value } = e.target;
        const numericValue = value === '' ? null : parseFloat(value);

        if (name === 'numberOfVehicles') {
            setNumberOfVehicles(numericValue);
            calculateMonthlyRequirement(numericValue, chargesPerWeek, averageChargeSize);
        } else if (name === 'chargesPerWeek') {
            setChargesPerWeek(numericValue);
            calculateMonthlyRequirement(numberOfVehicles, numericValue, averageChargeSize);
        } else if (name === 'averageChargeSize') {
            setAverageChargeSize(numericValue);
            calculateMonthlyRequirement(numberOfVehicles, chargesPerWeek, numericValue);
        }

    };

    async function fetchQuotes() {
        let bodyData: { [name: string]: any } = {};
        const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
        bodyData["uuid"] = userInfo.uuid;
        bodyData["businessUuid"] = userInfo.businessUuid;
        bodyData["numberOfVehicles"] = numberOfVehicles;
        bodyData["chargesPerWeek"] = chargesPerWeek;
        bodyData["averageChargeSize"] = averageChargeSize;

        let fetch_link = `${rootUrl}/cpo/ppa/quotes/get`;
        let fetch_option = {
            method: "POST",
            headers: {
                cache: "no-cache",
                pragma: "no-cache",
                "Cache-Control": "no-cache",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
        };
        let response = await fetch(fetch_link, fetch_option);

        let res_json = await response.json();

        setQuotes(res_json.quotes);
        setLoading(false);

    }



    const acceptQuote = async (chargePointOperatorUuid: string, chargingPartnerRateCardUuid: string, rowId: any) => {

        console.log("cp " + chargePointOperatorUuid + "|" + chargingPartnerRateCardUuid);
        setIsDisabled(true);

        try {
            let bodyData: { [name: string]: any } = {};

            bodyData["uuid"] = userInfo.uuid;
            bodyData["businessUuid"] = userInfo.businessUuid;
            bodyData["chargePointOperatorUuid"] = chargePointOperatorUuid;
            bodyData["chargingPartnerRateCardUuid"] = chargingPartnerRateCardUuid;
            bodyData["numberOfVehicles"] = numberOfVehicles;
            bodyData["chargesPerWeek"] = chargesPerWeek;
            bodyData["averageChargeSize"] = averageChargeSize;

            const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin;
            let fetch_link = `${rootUrl}/cpo/ppa/quote/accept`;
            let fetch_option = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(bodyData),
            };
            let response = await fetch(fetch_link, fetch_option);
            if (response.status !== 200) {
                setIsError(true);
                return;
            }
            let res_json = await response.json();

            if (response.ok) {
                // If the API call is successful, update the status of the corresponding row.
                const updatedData = quotes.map((row: { id: any; }) =>
                    row.id === rowId ? { ...row, workflowState: res_json.workflowState /* or whatever your actual key is */ } : row
                );
                setQuotes(updatedData);
                setIsDisabled(false);
                toast.success('Power Purchase Agreement setup Successful', {
                    position: 'bottom-center',
                    duration: 5000,
                });
                navigate("/agree/power-purchase-agreement");

            } else {
                setIsDisabled(false);
                throw new Error(res_json.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
            // Handle your error states appropriately
        }
    };


    return (
        <>
            {loading ? (
                <>Loading ...</>
            ) : (
                <div>
                    <div className="container-fluid g-0">
                        <div className="row gy-2">

                            <div className="col-xs-12 col-md-4 col-xl-4">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-title">
                                        <span className="icon material-symbols-outlined">view_timeline</span>
                                        Power Requirements
                                    </div>
                                    <div
                                        className="dashboard-card-content"
                                        style={{ gap: 20 }}>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Number of Vehicles :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <input
                                                    ref={currentInput}
                                                    defaultValue={numberOfVehicles !== null ? numberOfVehicles : ""}
                                                    type="text"
                                                    style={{ textAlign: "left" }}
                                                    className="textbox"
                                                    name="numberOfVehicles"
                                                    id="numberOfVehicles"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Charges per week :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <input
                                                    ref={currentInput}
                                                    defaultValue={chargesPerWeek !== null ? chargesPerWeek : ""}
                                                    type="text"
                                                    style={{ textAlign: "left" }}
                                                    className="textbox"
                                                    name="chargesPerWeek"
                                                    id="chargesPerWeek"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Average Charge size (kW/h) :
                                            </span>
                                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", width: 180 }}>
                                                <input
                                                    ref={currentInput}
                                                    defaultValue={averageChargeSize !== null ? averageChargeSize : ""}
                                                    type="text"
                                                    style={{ textAlign: "left" }}
                                                    className="textbox"
                                                    name="averageChargeSize"
                                                    id="averageChargeSize"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Monthly Requirement: {monthlyRequirementKWh?.toFixed(2)} kW/h
                                            </span>
                                        </div>
                                        {/* <hr/>
                                        <div className="box-info">
                                            <span
                                                className="item"
                                                style={{ width: 300 }}>
                                                Select any preferred Operators
                                            </span>
                                        </div>
                                        <LogoGrid></LogoGrid> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xs-12 col-md-8 col-xl-8">
                                <div className="dashboard-card">
                                    <div className="dashboard-card-title">
                                        <span className="icon material-symbols-outlined">view_timeline</span>
                                        Network Map
                                    </div>
                                    <Map
                                        selectedLocationDwelling={[]}
                                        vehicleMapData={[]}
                                        chargePoints={[]}
                                        polygons={[]}
                                        height={800}
                                    />
                                </div>
                            </div> */}
                        </div >
                    </div>
                    <div className="col-xs-12 col-md-12 col-xl-12">
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", gap: 50 }}>

                                <button
                                    className="primary-btn"
                                    disabled={isDisabled}
                                    onClick={fetchQuotes}
                                >
                                    Fetch Quotes
                                </button>
                            </div>
                        </div>
                    </div>
                    <br />

                    <PowerPurchaseAgreementQuotesTable businessChargingCategory={userInfo.businessCategory} quotes={quotes} acceptQuote={acceptQuote} />
                </div>

            )}
        </>
    );
}

export default PowerPurchaseAgreementCreatePage;