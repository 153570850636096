import { getFleetMenuOptions, getBusinessMenuOptions } from "./menu/menuOptions";
export default function generatePageInfo(url: string, state: any, userRole: string, product: string | null, businessCategory: string) {

    const businessMenu = getBusinessMenuOptions(product, businessCategory);
    const fleetMenu = getFleetMenuOptions();

    switch (url) {

        case "/agree":
        case "/agree/dashboard":
            return {
                title: "Charge Point Operator",
                secondMenu: true,
                helpText: "Dashboard Help",
                // second_title: "Dashboard",
                active_menu: "Dashboard",
                menu: businessMenu,
                ctaButton: [],
            };
        case "/agree/charge-point-operator/rate-cards":
            return {
                back: true,
                title: "Charge Point Operator",
                secondMenu: true,
                helpText: "",
                active_menu: "PPA Rate Cards",
                menu: businessMenu,
                ctaButton: [],

            };
        case "/agree/charge-point-operator/ppa/businesses":
                return {
                    back: true,
                    title: "Charge Point Operator",
                    secondMenu: true,
                    helpText: "",
                    active_menu: "PPA Businesses",
                    menu: businessMenu,
                    ctaButton: [],
    
                };
        case "/agree/charge-point-operator/rate-cards/edit":
            return {
            back: true,
            title: "Charge Point Operator",
            secondMenu: true,
            helpText: "",
            active_menu: "Edit PPA Rate Card",
            menu: businessMenu,
            ctaButton: [],
        };
 
        case "/agree/power-purchase-agreement":
            return {
            back: false,
            title: "Power Purchase Agreement",
            secondMenu: true,
            helpText: "",
            active_menu: "Power Purchase Agreement",
            menu: businessMenu,
            ctaButton: [],
        };

        case "/agree/power-purchase-agreement/create":
            return {
            back: true,
            title: "Power Purchase Agreement",
            secondMenu: true,
            helpText: "",
            active_menu: "Power Purchase Agreement",
            menu: businessMenu,
            ctaButton: [],
        };

        case "/agree/charge-point-operator/map":
            return {
            back: true,
            title: "Charging Network",
            secondMenu: true,
            helpText: "",
            active_menu: "Charging Network",
            menu: businessMenu,
            ctaButton: [],
        };



        case "/plan":
        case "/plan/dashboard":
            return {
                title: "Business",
                secondMenu: true,
                helpText: "Dashboard Help",
                // second_title: "Dashboard",
                active_menu: "Dashboard",
                menu: businessMenu,
                ctaButton: [],
            };


        case "/share":
        case "/share/dashboard":
            return {
                title: "Business",
                secondMenu: true,
                helpText: "Dashboard Help",
                // second_title: "Dashboard",
                active_menu: "Dashboard",
                menu: businessMenu,
                ctaButton: [],
            };
        case "/share/business/charging-partners/map":
            return {
                title: "Charging Partners",
                secondMenu: true,
                helpText: "",
                active_menu: "Network",
                menu: businessMenu,
                ctaButton: [
                    {
                        title: "Charging Partners",
                        url: "/share/business/charging-partners",
                        state: {},
                    },
                    {
                        title: "Transactions",
                        url: "/share/business/charging-transactions",
                        state: {},
                    },
                    {
                        title: "Configuration",
                        url: "/share/business/partner-charging-network",
                        state: {},
                    },
                ],
            };


        case "/common/user/list":
            return {
                title: "Dashboard",
                secondMenu: true,
                helpText: "List User Help",
                back: true,
                second_title: "List Users",
                active_menu: "Dashboard",
                menu: [
                    { title: "Dashboard", url: "/plan/dashboard" },
                ],
                ctaButton: [
                    {
                        title: "Add User",
                        url: "user/add",
                    },
                ],
            };
        case "/common/user/add":
            return {
                title: "Dashboard",
                secondMenu: true,
                helpText: "Add User help",
                back: true,
                second_title: "Add User",
                active_menu: "Dashboard",
                menu: [
                    { title: "Dashboard", url: "/plan/dashboard" },
                ],
                ctaButton: [
                    {
                        title: "User List",
                        url: "user/list",
                    },
                ],
            };

        case "/common/add-business-fleet":
            return {
                title: "Dashboard",
                secondMenu: false,
                helpText: "Creat business and fleet help",
                back: true,
                second_title: "Create Business and Fleet",
                active_menu: "Dashboard",
                menu: [{ title: "Dashboard", url: "/plan/dashboard" }],
                ctaButton: [],
            };

        case "/plan/insight/operational-cost-model":
            return {
                title: "Business Insight",
                secondMenu: true,
                helpText: "",
                active_menu: `Operational Cost Models`,
                second_title: `Operational Cost Model ${state.operationalCostModelVersion}`,
                back: true,
                menu: businessMenu,
                ctaButton: [],
            };

        case "/plan/business/insight":
            return {
                title: "Business Insight",
                secondMenu: true,
                helpText: "",
                active_menu: `Insight`,
                second_title: `Business Insight`,
                // back: true,
                menu: businessMenu,
                ctaButton: [],
            };

        case "/plan/insight/operational-cost-model-summary":
            return {
                title: "Business",
                secondMenu: true,
                helpText: "",
                active_menu: `Operational Cost Models`,
                second_title: `Operational Cost Models`,
                // back: true,
                menu: businessMenu,
                ctaButton: [],
            };

        case "/plan/insight/fleet":
            return {
                title: "Business Insight",
                secondMenu: true,
                helpText: "Fleet Insight Help",
                active_menu: `Fleet Insight`,
                second_title: `Fleet Insight`,
                // active_menu: "Scenario",
                menu: businessMenu,
                ctaButton: [],
            };
        case "/plan/insight/fleet/scenario/insight":
            return {
                title: "Fleet Insight",
                secondMenu: true,
                helpText: "Fleet Insight Help",
                active_menu: `Overview`,
                second_title: `Scenario ${state.fleetScenarioNumber} Overview`,
                back: true,
                menu: [
                    {
                        title: "Overview",
                        url: "/plan/insight/fleet/scenario/insight",
                        state: {
                            fleetScenarioNumber: state.fleetScenarioNumber,
                            fleetScenarioUuid: state.fleetScenarioUuid,
                        },
                    },
                ],
                ctaButton: [
                    {
                        title: "Categories",
                        url: "/plan/insight/fleet/scenario/categories",
                        state: {
                            fleetScenarioNumber: state.fleetScenarioNumber,
                            fleetScenarioUuid: state.fleetScenarioUuid,
                        },
                    },
                ],
            };
        case "/plan/insight/fleet/scenario/categories":
            return {
                title: "Fleet Insight",
                secondMenu: true,
                helpText: `Scenario ${state.fleetScenarioNumber} Categories Help`,
                active_menu: `Categories`,
                second_title: `Scenario ${state.fleetScenarioNumber} Categories`,
                back: true,
                menu: [
                    {
                        title: "Overview",
                        url: "/plan/insight/fleet/scenario/insight",
                        state: {
                            fleetScenarioNumber: state.fleetScenarioNumber,
                            fleetScenarioUuid: state.fleetScenarioUuid,
                        },
                    },
                    {
                        title: "Categories",
                        url: "/plan/insight/fleet/scenario/categories",
                        state: { fleetScenarioUuid: state.fleetScenarioUuid },
                    },
                ],
                ctaButton: [],
            };
        case "/plan/insight/fleet/scenario/categories/vehicle-insight":
            return {
                title: "Fleet Insight",
                secondMenu: true,
                helpText: `${state.iceVehicle.registrationNumber} Vehicle Insight Help`,
                active_menu: `Categories`,
                second_title: `${state.iceVehicle.registrationNumber} Vehicle Insight`,
                back: true,
                menu: [
                    {
                        title: "Overview",
                        url: "/plan/insight/fleet/scenario/insight",
                        state: {
                            fleetScenarioNumber: state.fleetScenarioNumber,
                            fleetScenarioUuid: state.fleetScenarioUuid,
                        },
                    },
                    {
                        title: "Categories",
                        url: "/plan/insight/fleet/scenario/categories",
                        state: { fleetScenarioUuid: state.fleetScenarioUuid },
                    },
                ],
                ctaButton: [],
            };
        case "/plan/insight/fleet/scenario/categories/vehicle-insight/journey-insight":
            return {
                title: "Fleet Insight",
                secondMenu: true,
                helpText: `${state.description} Journey Insight Help`,
                active_menu: `Categories`,
                second_title: `${state.description} Journey Insight`,
                back: true,
                menu: [
                    {
                        title: "Overview",
                        url: "/plan/insight/fleet/scenario/insight",
                        state: {
                            fleetScenarioNumber: state.fleetScenarioNumber,
                            fleetScenarioUuid: state.fleetScenarioUuid,
                        },
                    },
                    {
                        title: "Categories",
                        url: "/plan/insight/fleet/scenario/categories",
                        state: { fleetScenarioUuid: state.fleetScenarioUuid },
                    },
                ],
                ctaButton: [],
            };
        case "/plan/scenario/configuration":
        case "/plan/scenario/configuration/ice-vehicles":
            return {
                title: "Fleet",
                secondMenu: true,
                helpText: "",
                active_menu: "Ice Vehicles",
                menu: fleetMenu,
                ctaButton: [
                    {
                        title: "Upload Telematics Data",
                        url: "scenario/configuration/ice-vehicles/upload",
                    },
                ],
            };
        case "/plan/scenario/configuration/ice-vehicles/upload":
            return {
                back: true,
                secondMenu: true,
                helpText: "",
                active_menu: "Ice Vehicles",
                title: "Scenario Configuration",
                second_title: "Upload Telematics Data",
                menu: fleetMenu,
                ctaButton: [],
            };
        case "/plan/scenario/configuration/ev-vehicles":
            return {
                title: "Fleet",
                secondMenu: true,
                helpText: "",
                active_menu: "Electric Vehicles",
                menu: fleetMenu,
                ctaButton: [],
            };
        case "/plan/scenario/configuration/parameters":
            return {
                title: "Fleet",
                secondMenu: true,
                helpText: "",
                active_menu: "Fleet Scenario Parameters",
                menu: fleetMenu,
                ctaButton: [],
            };

        case "/plan/business/surveys/send-out-survey":
            return {
                title: "Business",
                secondMenu: true,
                helpText: "",
                active_menu: "User Surveys",
                second_title: "Send Out Survey",
                back: true,
                menu: businessMenu,
                ctaButton: [],
            };
        case "/plan/business/surveys/add-survey-response":
            return {
                title: "Scenario Configuration",
                secondMenu: true,
                helpText: "",
                second_title: "Add Survey Response",
                active_menu: "User Surveys",
                back: true,
                menu: businessMenu,
                ctaButton: [],
            };

        case "/plan/business/surveys/edit":
            return {
                title: "Business",
                secondMenu: true,
                helpText: "",
                second_title: "Update Survey",
                active_menu: "User Surveys",
                back: true,
                menu: businessMenu,
                ctaButton: [
                    {
                        title: "Send Out Survey Emails",
                        url: "business/surveys/send-out-survey",
                    },
                    {
                        title: "Add Survey Response",
                        url: "business/surveys/add-survey-response",
                    },
                ],
            };

        case "/share/business/charging-infrastructure/dwellings":
            return {
                back: true,
                title: "Business",
                secondMenu: true,
                helpText: "",
                second_title: "Charging Infrastructure Dwellings",
                active_menu: "Dwellings",
                menu: businessMenu,
                ctaButton: [],
            };


        case "/share/business/partner-charging-network":
            return {
                back: true,
                title: "Business",
                secondMenu: true,
                helpText: "",
                second_title: "Configuration",
                active_menu: "Configuration",
                menu: businessMenu,
                ctaButton: [
                    {
                        title: "Site Access Policies",
                        url: "/share/business/site-access-policies",
                        state: {},
                    },
                    {
                        title: "Rate Cards",
                        url: "/share/business/charging-partners/rate-cards",
                        state: {},
                    },],
            };
        case "/share/business/advanced-bookings":
            return {
                back: true,
                title: "Business",
                secondMenu: true,
                helpText: "",
                second_title: "Advanced Bookings",
                active_menu: "Advanced Bookings",
                menu: businessMenu,
                ctaButton: [],
            };

        case "/plan/scenario":
            return {
                title: "Scenario Configuration",
                secondMenu: true,
                helpText: "",
                second_title: "Add Survey Response",
                active_menu: "User Surveys",
                back: true,
                menu: fleetMenu,
                ctaButton: [],
            };
        case "/plan/scenario/configuration/run":
            return {
                title: "Fleet",
                secondMenu: true,
                helpText: "",
                active_menu: "Run Scenario",
                menu: fleetMenu,
                ctaButton: [],
            };
        case "/plan/business/surveys":
            return {
                title: "Business",
                secondMenu: true,
                helpText: "",
                active_menu: "User Surveys",
                menu: businessMenu,
                ctaButton: [
                    {
                        title: "Send Out Survey Emails",
                        url: "business/surveys/send-out-survey",
                    },
                    {
                        title: "Add Survey Response",
                        url: "business/surveys/add-survey-response",
                    },
                ],

            };
        case "/share/business/site-access-policies":
            return {
                back: true,
                title: "Site Access Policies",
                secondMenu: true,
                helpText: "",
                active_menu: "Site Access Policies",
                menu: businessMenu,
                ctaButton: [

                    {
                        title: "Add Site Access Policies",
                        url: "/share/business/site-access-policies/edit",
                    },
                ],
            };
        case "/share/business/site-access-policies/edit":
            return {
                back: true,
                title: "Site Access Policies",
                secondMenu: true,
                helpText: "",
                second_title: "Add Site Access Policies",
                menu: businessMenu,
                ctaButton: [],

            };

        case "/share/business/charging-partners":
            return {
                back: true,
                title: "Charging Partners",
                secondMenu: true,
                helpText: "",
                active_menu: "Charging Partners",
                menu: businessMenu,
                ctaButton: [],

            };



        case "/share/business/charging-partners/edit":
            return {
                back: true,
                title: "Charging Partners",
                secondMenu: true,
                helpText: "",
                second_title: `${state.chargingPartnerBusinessName}`,
                menu: businessMenu,
                ctaButton: [],
            };
        case "/share/business/charging-partners/request":
            return {
                back: true,
                title: "Charging Partners",
                secondMenu: true,
                helpText: "",
                second_title: `${state.partnershipRequestType === 'REQUEST' ? 'Request partnership with' : 'Offer partnership to'} ${state.chargingPartnerBusinessName}`,
                menu: businessMenu,
                ctaButton: [],
            };
        case "/share/business/charging-partners/offer/review":
            return {
                back: true,
                title: "Charging Partners",
                secondMenu: true,
                helpText: "",
                second_title: `${state.requestType === 'FROM' ? 'Review offer from' : state.requestType === 'AGREE' ? 'Charging Partnership with ' : 'Review offer to'} ${state.chargingPartnerBusinessName}`,
                menu: businessMenu,
                ctaButton: [],
            };
        case "/share/business/charging-partners/rate-cards":
            return {
                back: true,
                title: "Rate Cards",
                secondMenu: true,
                helpText: "",
                active_menu: "Rate Cards",
                menu: businessMenu,
                ctaButton: [],

            };
        case "/share/business/charging-partners/rate-cards/edit":
            return {
                back: true,
                title: "Charging Partners Rate Card",
                secondMenu: true,
                helpText: "",
                active_menu: "Charging Partners Rate Card",
                menu: businessMenu,
                ctaButton: [],
            };
        case "/share/business/charging-transactions":
            return {
                back: true,
                title: "Charging Transactions",
                secondMenu: true,
                helpText: "",
                active_menu: "Charging Transactions",
                menu: businessMenu,
                ctaButton: [],
            };

        case "/share/business/electric-vehicles":
            return {
                title: "Electric Vehicles",
                secondMenu: true,
                helpText: "",
                active_menu: "Electric Vehicles",
                menu: businessMenu,
                ctaButton: [
                    {
                        title: "Add Electric Vehicle",
                        url: "/share/business/electric-vehicles/edit",
                        state: {},
                    },
                ],
                
            };

        case "/share/business/electric-vehicles/edit":
            return {
                title: "Electric Vehicles",
                secondMenu: true,
                helpText: "",
                back: true,
                active_menu: "Add Electric Vehicle",
                menu: businessMenu,
                ctaButton: [],
            };

        case "/share/business/make-advanced-booking":
            return {
                title: "Make Advanced Booking",
                back: true,
                secondMenu: true,
                helpText: "",
                active_menu: "Advanced Booking",
                menu: businessMenu,
                ctaButton: [],
            };

        case "/plan/business":

        case "/plan/business/location/charge-points/list":
            return {
                title: "Location",
                secondMenu: true,
                helpText: "",
                back: true,
                active_menu: `Charge Points`,
                second_title: `Charge Points`,
                // back: true,
                menu: [{
                    title: "Charge Points",
                    url: "/plan/business/location/charge-points/list",
                },


                ], ctaButton: [
                    {
                        title: "Advanced Bookings",
                        url: "/share/business/advanced-bookings",
                        state: {
                            dwellingUuid: state.dwellingUuid,
                            businessUuid: state.businessUuid,
                        },
                    },
                ],
            };

        case "/plan/business/locations":
            return {
                title: "Business",
                secondMenu: true,
                helpText: "",
                active_menu: `Locations`,
                second_title: `Locations`,
                // back: true,
                menu: businessMenu,
                ctaButton: [
                    {
                        title: "Add Location",
                        url: "business/locations/add-location",
                    },
                    {
                        title: "Export Locations",
                        action: true,
                        url: "upload",
                    },
                    {
                        title: "Import Locations",
                        url: "business/locations/import-locations",
                    },
                ],
            };
        case "/plan/business/locations/add-location":
            return {
                back: true,
                secondMenu: true,
                helpText: "",
                active_menu: "Locations",
                second_title: "Add Location",
                title: "Business",
                menu: businessMenu,
                ctaButton: [],
            };
        case "/plan/business/locations/import-locations":
            return {
                back: true,
                secondMenu: true,
                helpText: "",
                active_menu: "Locations",
                second_title: "Import Locations",
                title: "Business",
                menu: businessMenu,
                ctaButton: [],
            };

        case "/plan/business/location/detail/location-insight":
            return {
                title: "Business Insight",
                secondMenu: true,
                helpText: "",
                active_menu: `Operational Cost Models`,
                second_title: state.fleetDwellingName,
                back: true,
                menu: [
                    {
                        title: "Operational Cost Models",
                        url: "/plan/insight/operational-cost-model",
                    },
                ],
                ctaButton: [{ title: "CHARGING INFRASTRUCTURE USAGE", url: "/plan/business/location/detail/charging-infrastructure-insight" }],
            };

        case "/plan/business/location/detail/charging-infrastructure-insight":
            return {
                title: "Charging Infrastructure Insight",
                secondMenu: true,
                helpText: "",
                active_menu: `Charging Infrastructure Usage`,
                // second_title: state.fleetDwellingName,
                back: true,
                menu: [
                    {
                        title: "Operational Cost Models",
                        url: "/plan/insight/operational-cost-model",
                    },
                ],
                ctaButton: [],
            };

        case "/common/profile":
            return {
                title: "Profile",
                secondMenu: true,
                helpText: "",
                menu: [],
                ctaButton: [],
            };
        case "/plan/insight/fleet/scenario/categories/vehicle-insight":
            return {
                title: `Insight for ${state.iceVehicle.registrationNumber}`,
                secondMenu: true,
                helpText: "",
                menu: [],
                ctaButton: [],
            };
        case "/plan/insight/fleet/scenario/categories/vehicle-insight/journey-insight":
            return {
                title: "Journey Insight",
                secondMenu: true,
                helpText: "",
                menu: [],
                ctaButton: [],
            };
        case "/plan/insight/fleet/scenario/categories":
            return {
                title: "Fleet Insight",
                secondMenu: true,
                helpText: "",
                menu: [],
                ctaButton: [],
            };
        default:
            return {
                title: "",
                secondMenu: true,
                helpText: "",
                menu: [],
                ctaButton: [],
            };
    }

}