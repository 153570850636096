export const PLAN = "PLAN";
export const SHARE = "SHARE";
export const AGREE = "AGREE";

export const CHARGE_POINT_OPERATOR = "CHARGE_POINT_OPERATOR";
export const BUSINESS_WITH_VEHICLES = "BUSINESS_WITH_VEHICLES";
export const BUSINESS_WITH_CHARGERS = "BUSINESS_WITH_CHARGERS";
export const BUSINESS_WITH_CHARGERS_AND_VEHICLES = "BUSINESS_WITH_CHARGERS_AND_VEHICLES";

export const isMultiProduct = (products: string[]): boolean => {
    return products?.length > 1;
};

export const isPlanProduct = (product: string): boolean => {
    return PLAN === product;
};

export const isChargeProduct = (product: string): boolean => {
    return SHARE === product;
};

export const isAgreeProduct = (product: string): boolean => {
    return AGREE === product;
};

export const isChargePointOperator = (chargingCategory: string): boolean => {

    return CHARGE_POINT_OPERATOR === chargingCategory;

};

export const hasSharedInfrastructure = (category: string): boolean => {
    return BUSINESS_WITH_CHARGERS === category || BUSINESS_WITH_CHARGERS_AND_VEHICLES === category;
};

export const hasChargingRequirement = (category: string): boolean => {
    return BUSINESS_WITH_VEHICLES === category || BUSINESS_WITH_CHARGERS_AND_VEHICLES === category;
};

export const displayProductButton = (product: string, businessProducts: string[]): boolean => {

    return businessProducts.includes(product);

    return false;
}

