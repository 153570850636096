import React, { useEffect, useState } from "react";
import BackgroundVideo from "../../../static/video/background.mp4";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./VideoComponent.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";

function VideoComponent(props: any) {
  let backgroundVideo = process.env.NODE_ENV === "production" ? process.env.REACT_APP_CDN_URL + "/static/video/background.mp4" : BackgroundVideo;
  const navigate = useNavigate();
  const title_list = ["Electrifying commercial vehicles isn't always economically viable.", "Operational downtime from public charging keeps vehicles off the road instead of working.", "The cost of public charging often shatters the business case for electrification.", "The location of charging infrastructure often requires vehicles to reroute. "];
  const [index, setIndex] = useState(0);

  const goDashboard = () => {
    navigate("/plan");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (index + 1 >= title_list.length) {
        setIndex(0);
      } else {
        setIndex((prev) => prev + 1);
      }
    }, 4000);
    return () => clearInterval(interval);
  });
  return (
    <div className="video-container">
      <video
        src={backgroundVideo}
        muted
        loop
        autoPlay
      />
      <div className="video-content">
        <TransitionGroup>
          <CSSTransition
            key={index}
            timeout={1000}
            classNames="title-primary"
            unmountOnExit>
            <div className="dynamic-title">{title_list[index]}</div>
          </CSSTransition>
        </TransitionGroup>
        <div className="paragraph">Evata is rewriting the economics of fleet electrification, enabling fleet operators to electrify their vehicles whilst profiting from the transition. </div>
       </div>
    </div>
  );
}

export default VideoComponent;
