import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

// page imports
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import HomePage from "./pages/HomePage/HomePage";
// import Home from './pages/Home/Home'

/* TEMPORARY */

// component imports

// import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import UnProtectedRouteLayout from "./layout/UnProtectedRouteLayout";

import AboutPage from "./pages/AboutPage/AboutPage";
import ChargingPartnerEditPage from "./pages/ChargingPartnerPage/ChargingPartnerEditPage";
import ChargingPartnerRequestPage from "./pages/ChargingPartnerPage/ChargingPartnerRequestPage";

import ChargingPartnerReviewOfferPage from "./pages/ChargingPartnerPage/ChargingPartnerReviewOfferPage";
import ChargingPartnerListPage from "./pages/ChargingPartnerPage/ChargingPartnerListPage";
import ChargingPartnerMapPage from "./pages/ChargingPartnerPage/ChargingPartnerMapPage";
import ChargingPartnerRateCardEditPage from "./pages/ChargingPartnerRateCardPage/ChargingPartnerRateCardEditPage";
import ChargingPartnerRateCardListPage from "./pages/ChargingPartnerRateCardPage/ChargingPartnerRateCardListPage";
import ChargingTransactionListPage from "./pages/ChargingTransactionPage/ChargingTransactionListPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import DwellingDetailPage from "./pages/DwellingDetailPage/DwellingDetailPage";
import ElectricVehicleEditPage from "./pages/ElectricVehiclePage/ElectricVehicleEditPage";
import ElectricVehicleListPage from "./pages/ElectricVehiclePage/ElectricVehicleListPage";
import FleetInsight from "./pages/FleetInsightPage/FleetInsight";
import FleetScenarioPage from "./pages/FleetScenarioPage/FleetScenarioPage";
import FleetScenarioSummaryPage from "./pages/FleetScenarioSummaryPage/FleetScenarioSummaryPage";
import FrequenceQuestionPage from "./pages/FrequenceQuestionPage/FrequenceQuestionPage";
import InsightPage from "./pages/InsightPage/InsightPage";
import JourneyInsightPage from "./pages/JourneyInsightPage/JourneyInsightPage";
import Membership from "./pages/MembershipPage/Membership";
import PowerPurchaseAgreementCreatePage from "pages/PowerPurchaseAgreementPage/PowerPurchaseAgreementCreatePage";
import PowerPurchaseAgreementPage from "pages/PowerPurchaseAgreementPage/PowerPurchaseAgreementPage";
import PrivacyPolicy from "./pages/PrivacyPolicyPage/PrivacyPolicy";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import SiteAccessPolicyEditPage from "./pages/SiteAccessPolicyPage/SiteAccessPolicyEditPage";
import SiteAccessPolicyListPage from "./pages/SiteAccessPolicyPage/SiteAccessPolicyListPage";
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import TermConditionPage from "./pages/TermConditionPage/TermConditionPage";
import UserSurveyPage from "./pages/UserSurveyPage/UserSurveyPage";


import AddNewLocationPage from "components/business/InfrastructureComponent/AddNewLocationPage";
import ImportLocationPage from "components/business/LocationComponents/ImportLocationPage";
import ComparisonElectricVehicleTabScreen from "components/fleet/FleetScenarioConfigPageComponent/ComparisonElectricVehicleTabScreen/ComparisonElectricVehicleTabScreen";
import ConfigureTabScreen from "components/fleet/FleetScenarioConfigPageComponent/ConfigureTabScreen/ConfigureTabScreen";
import IceVehiclePage from "components/fleet/FleetScenarioConfigPageComponent/IceVehicleTabScreen/IceVehiclePage";
import IceVehicleRouter from "components/fleet/FleetScenarioConfigPageComponent/IceVehicleTabScreen/IceVehicleRouter";
import UploadIceVehiclePage from "components/fleet/FleetScenarioConfigPageComponent/IceVehicleTabScreen/UploadIceVehiclePage";
//import InfrastructurePage from "components/FleetScenarioConfigPageComponent/InfrastructureTabScreen/InfrastructurePage";
import BusinessContextProvider from "Provider/BusinessContextProvider";
import ScenarioConfigContextProvider from "Provider/ScenarioConfigContextProvider";
import RunPage from "components/fleet/FleetScenarioConfigPageComponent/RunTabScreen/RunPage";
import SurveyRouter from "components/fleet/FleetScenarioConfigPageComponent/SurveyTabScreen/SurveyRouter";
import AddEmailSurvey from "components/user/UserSurveyComponent/AddEmailSurvey";
import AddBusinessFleetPage from "pages/AddBusinessFleetPage/AddBusinessFleetPage";
import AddUserPage from "pages/AddUserPage/AddUserPage";
import BusinessInsightPage from "pages/BusinessInsightPage/BusinessInsightPage";
import ChargingInfrastructurePage from "pages/ChargingInfrastructurePage/ChargingInfrastructurePage";
import LoginPage from "pages/LoginPage/LoginPage";
import OperationalCostModelPage from "pages/OperationalCostModelPage/OperationalCostModelPage";
import OperationalCostModelSummaryPage from "pages/OperationalCostModelSummaryPage/OperationalCostModelSummaryPage";
import RequiredAuth from "pages/RequiredAuth/RequiredAuth";
import RoleAuth from "pages/RoleAuth/RoleAuth";
import AuthenticatedSurveyPage from "pages/SurveyPage/AuthenticatedSurveyPage";
import UserListPage from "pages/UserListPage/UserListPage";
import UnauthenticatedSurveyPage from "./pages/SurveyPage/UnauthenticatedSurveyPage";

import AdvancedBookingPage from "pages/AdvancedBookingPage/AdvancedBookingPage";
import BusinessChargingNetworkParametersPage from "pages/BusinessChargingNetworkParametersPage/BusinessChargingNetworkParametersPage";
import BusinessDwellingListPage from "pages/BusinessDwellingListPage/BusinessDwellingListPage";
import ChargingAdvancedBookingsPage from "pages/ChargingAdvancedBookingsPage/ChargingAdvancedBookingsPage";
import JoinUsPage from "pages/JoinUsPage/JoinUsPage";
import LocationChargePointsListPage from "pages/LocationChargePointsListPage/LocationChargePointsListPage";
import RegisterPage from "pages/Register/RegisterPage";
import InfrastructurePage from "./pages/InfrastructurePage/InfrastructurePage";
import ChargePointOperatorMapPage from "pages/ChargePointOperator/ChargePointOperatorMapPage";
import PowerPurchaseAgreementListPage from "pages/PowerPurchaseAgreementListPage/PowerPurchaseAgreementListPage";
import PowerPurchaseAgreementPublicPage from "pages/PowerPurchaseAgreementPublicPage/PowerPurchaseAgreementPublicPage";
import PowerPurchaseAgreementPublicSuccessPage from "pages/PowerPurchaseAgreementPublicPage/PowerPurchaseAgreementPublicSuccessPage";


export default function AppRoutes(props: any) {
    const [sitePassAuthed, setSitePassAuthed] = useState(false);
    const navigate = useNavigate();
    // const [notifications, setNotifications] = useState([]) as any
    const [error, setError] = useState(false);
    // const { isAuthenticated, user, loginWithRedirect } = useAuth0();
    const [user, setUser] = useState({}) as any;
    // const { isLoading } = useAuth0();
    const [loading, setLoading] = useState(true);

    const [scenarioConfigInfo, setScenarioConfigInfo] = useState({
        unCompletedScenario: {} as any,
    });
    const [siteLoaded, setSiteLoaded] = useState(false);

    return (

        <Routes>
            <Route
                path="/"
                element={<UnProtectedRouteLayout />}>
                <Route
                    path="login"
                    element={<LoginPage />}
                />

                <Route
                    path=""
                    element={<HomePage />}
                />
                <Route
                    path="contact"
                    element={<ContactPage />}
                />
                <Route
                    path="launch"
                    element={<JoinUsPage />}
                />
                <Route
                    path="register"
                    element={<RegisterPage />}
                />
                <Route
                    path="membership"
                    element={<Membership />}
                />
                <Route
                    path="subscription"
                    element={<SubscriptionPage />}
                />
                <Route
                    path="about"
                    element={<AboutPage />}
                />
                <Route
                    path="term_condition"
                    element={<TermConditionPage />}
                />
                <Route
                    path="frequence_question"
                    element={<FrequenceQuestionPage />}
                />
                <Route
                    path="privacy_policy"
                    element={<PrivacyPolicy />}
                />
                <Route
                    path="ppa"
                    element={<PowerPurchaseAgreementPublicPage />}>
                </Route>
                <Route
                    path="ppa/success"
                    element={<PowerPurchaseAgreementPublicSuccessPage />} />
            </Route>
            <Route
                path="/plan"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path=""
                    element={<RoleAuth products={["PLAN"]} />}>
                    <Route
                        path=""
                        element={
                            <DashboardPage
                                name={"dashboard"}
                                error={[error, setError]}
                            />
                        }
                    />
                    <Route
                        path="dashboard"
                        element={
                            <DashboardPage
                                name={"dashboard"}
                                error={[error, setError]}
                            />
                        }
                    />

                    <Route path="business">
                        <Route path="locations">
                            <Route
                                path=""
                                element={<InfrastructurePage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["PLAN", "SHARE"]} />}>
                                <Route
                                    path="add-location"
                                    element={<AddNewLocationPage error={[error, setError]} />}
                                />

                                <Route
                                    path="import-locations"
                                    element={<ImportLocationPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="location/charge-points/list"
                            element={<LocationChargePointsListPage error={[error, setError]} />}
                        />
                        <Route
                            path="location/detail"
                            element={<BusinessContextProvider />}>
                            <Route
                                path="location-insight"
                                element={<DwellingDetailPage error={[error, setError]} />}
                            />
                            <Route
                                path="charging-infrastructure-insight"
                                element={<ChargingInfrastructurePage error={[error, setError]} />}
                            />
                        </Route>



                        <Route
                            path="surveys"
                            element={<SurveyRouter error={[error, setError]} />}>
                            <Route
                                path=""
                                element={<UserSurveyPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["PLAN"]} />}>
                                <Route
                                    path="send-out-survey"
                                    element={<AddEmailSurvey error={[error, setError]} />}
                                />
                                <Route
                                    path="add-survey-response"
                                    element={<AuthenticatedSurveyPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="insight"
                            element={<BusinessInsightPage error={[error, setError]} />}
                        />

                    </Route>

                    <Route path="insight">
                        <Route
                            path="operational-cost-model-summary"
                            element={<OperationalCostModelSummaryPage error={[error, setError]} />}
                        />
                        <Route
                            path="operational-cost-model"
                            element={<OperationalCostModelPage error={[error, setError]} />}
                        />
                        <Route
                            path="fleet"
                            element={<FleetScenarioPage error={[error, setError]} />}
                        />
                        <Route
                            path="fleet/scenario/insight"
                            element={<FleetScenarioSummaryPage error={[error, setError]} />}
                        />
                        <Route
                            path="fleet/scenario/categories"
                            element={<FleetInsight error={[error, setError]} />}
                        />
                        <Route
                            path="fleet/scenario/categories/vehicle-insight"
                            element={<InsightPage error={[error, setError]} />}
                        />
                        <Route
                            path="fleet/scenario/categories/vehicle-insight/journey-insight"
                            element={<JourneyInsightPage error={[error, setError]} />}
                        />
                    </Route>
                    <Route
                        path="scenario/configuration"
                        element={
                            <ScenarioConfigContextProvider
                                loading={[loading, setLoading]}
                                error={[error, setError]}
                            />
                        }>
                        <Route
                            path=""
                            element={<IceVehicleRouter error={[error, setError]} />}>
                            <Route
                                path=""
                                element={<IceVehiclePage error={[error, setError]} />}
                            />
                            <Route
                                path="upload"
                                element={<UploadIceVehiclePage error={[error, setError]} />}
                            />
                        </Route>

                        <Route
                            path="ice-vehicles"
                            element={<IceVehicleRouter error={[error, setError]} />}>

                            <Route
                                path=""
                                element={<IceVehiclePage error={[error, setError]} />}
                            />

                            <Route
                                path=""
                                element={<RoleAuth />}>
                                <Route
                                    path="upload"
                                    element={<UploadIceVehiclePage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path="ev-vehicles"
                            element={<ComparisonElectricVehicleTabScreen error={[error, setError]} />}
                        />

                        <Route
                            path="parameters"
                            element={<ConfigureTabScreen error={[error, setError]} />}
                        />
                        <Route
                            path=""
                            element={<RoleAuth />}>
                            <Route
                                path="run"
                                element={<RunPage error={[error, setError]} />}
                            />
                        </Route>
                    </Route>

                </Route>
            </Route>

            <Route
                path="/share"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path=""
                    element={<RoleAuth products={["SHARE"]} />}>
                    <Route
                        path=""
                        element={
                            <DashboardPage
                                name={"dashboard"}
                                error={[error, setError]}
                            />
                        }
                    />
                    <Route path="business">
                        <Route path="charging-infrastructure/dwellings">
                            <Route path=""
                                element={<BusinessDwellingListPage error={[error, setError]} />}
                            />
                        </Route>


                        <Route path="site-access-policies">
                            <Route path=""
                                element={<SiteAccessPolicyListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<SiteAccessPolicyEditPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="charging-partners">
                            <Route path=""
                                element={<ChargingPartnerListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<ChargingPartnerEditPage error={[error, setError]} />}
                                />
                                <Route
                                    path="request"
                                    element={<ChargingPartnerRequestPage error={[error, setError]} />}
                                />
                                <Route
                                    path="offer/review"
                                    element={<ChargingPartnerReviewOfferPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="charging-partners/map">
                            <Route path=""
                                element={<ChargingPartnerMapPage error={[error, setError]} />}
                            />
                        </Route>
                        <Route path="charging-partners/rate-cards">
                            <Route path=""
                                element={<ChargingPartnerRateCardListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<ChargingPartnerRateCardEditPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="charging-transactions">
                            <Route path=""
                                element={<ChargingTransactionListPage error={[error, setError]} />}
                            />
                        </Route>
                        <Route path="electric-vehicles">
                            <Route path=""
                                element={<ElectricVehicleListPage error={[error, setError]} />}
                            />
                            <Route
                                path=""
                                element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                <Route
                                    path="edit"
                                    element={<ElectricVehicleEditPage error={[error, setError]} />}
                                />
                            </Route>
                        </Route>
                        <Route path="partner-charging-network">
                            <Route path=""
                                element={<BusinessChargingNetworkParametersPage error={[error, setError]} />}
                            />
                        </Route>
                        <Route path="make-advanced-booking">
                            <Route
                                path=""
                                element={<BusinessContextProvider />}>

                                <Route path=""
                                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                                    <Route
                                        path=""
                                        element={<AdvancedBookingPage error={[error, setError]} />}
                                    />
                                </Route>
                            </Route>
                        </Route>

                        <Route
                            path="advanced-bookings"
                            element={<BusinessContextProvider />}>

                            <Route path=""
                                element={<ChargingAdvancedBookingsPage error={[error, setError]} />}
                            />
                        </Route>

                    </Route>
                </Route>
            </Route>
            <Route
                path="/common"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path="user"
                    element={<RoleAuth />}>
                    <Route
                        path="list"
                        element={<UserListPage error={[error, setError]} />}
                    />

                    <Route
                        path=""
                        element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                        <Route
                            path="add"
                            element={<AddUserPage error={[error, setError]} />}
                        />
                    </Route>
                </Route>
                <Route
                    path="profile"
                    element={<ProfilePage error={[error, setError]} />}
                />
                <Route
                    path=""
                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} />}>
                    <Route
                        path="add-business-fleet"
                        element={<AddBusinessFleetPage error={[error, setError]} />}
                    />
                </Route>

                {/* <Route path='infrastructure/dwelling-detail' element={<DwellingDetailPage />} /> */}
                {/* <Route path='infrastructure/add-depot' element={<AddDeportLocationPage />} /> */}
                {/* <Route path='insight/fleet/scenario/categories/vehicle-insight' element={<InsightPage />} /> */}
                {/* <Route path='insight/fleet/scenario/categories' element={<FleetInsight />} /> */}
                <Route
                    path="insight/fleet/scenario/categories/vehicle-insight/journey-insight"
                    element={<JourneyInsightPage error={[error, setError]} />}
                />
            </Route>
            <Route
                path="user-survey"
                element={<UnProtectedRouteLayout />}>
                <Route
                    path="*"
                    element={<UnauthenticatedSurveyPage />}
                />
            </Route>
            <Route
                path="/agree"
                element={<RequiredAuth error={[error, setError]} />}>
                <Route
                    path=""
                    element={<RoleAuth products={["AGREE"]} />}>
                    <Route
                        path=""
                        element={
                            <DashboardPage
                                name={"dashboard"}
                                error={[error, setError]}
                            />
                        }
                    />
                </Route>
                <Route path="charge-point-operator">
                    <Route path="rate-cards"
                        element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["AGREE"]} />}
                    >
                        <Route
                            path=""
                            element={<ChargingPartnerRateCardListPage error={[error, setError]} />} />
                        <Route
                            path="edit"
                            element={<ChargingPartnerRateCardEditPage error={[error, setError]} />} />
                    </Route>
                    <Route path="map"
                        element={<ChargePointOperatorMapPage error={[error, setError]} />}>
                    </Route>
                    <Route path="ppa/businesses"
                        element={<PowerPurchaseAgreementListPage error={[error, setError]} />}>
                    </Route>


                </Route>
                <Route path="power-purchase-agreement"
                    element={<RoleAuth roles={["ACCOUNT_OWNER", "BUSINESS_OWNER"]} products={["AGREE"]} />}>
                    <Route
                        path=""
                        element={<PowerPurchaseAgreementPage error={[error, setError]} />}
                    />

                    <Route
                        path="create"
                        element={<PowerPurchaseAgreementCreatePage error={[error, setError]} />}
                    />
                </Route>
            </Route>

            <Route
                path="*"
                element={<ErrorPage />}
            />
        </Routes>
    );
}
