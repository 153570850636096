import './CopyBlock.scss'

const CopyBlock = ({ alignment, title1, title2, title3, title4, title5,text, image }: { alignment: string; title1: string; title2: string; title3: string; title4: string; title5: string;  text: string; image: any }) => {

	return (
		<div className={alignment}>
			<h2><span>{title1} <span className="hero-highlight">{title2}</span>{title3}<span className="hero-highlight">{title4}</span>{title5}</span></h2>
			<div className="sub-text">
				<span>{text}</span>
			</div>
			<img src={image} alt="" style={{ width: '100%', borderRadius: 10, boxShadow: "0px 3px 15px rgb(0 0 0 / 20%)" }} />
		</div>
	)
}

export default CopyBlock