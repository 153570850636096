import { useState } from "react"
import { IoMdClose } from "react-icons/io";
import ModalComponent from "../../common/Modal/Modal"

const SignupContainer = () => {
	const [modalIsOpen, setIsOpen] = useState(false)
	function openModal() {
		setIsOpen(true);
	}
	function closeModal() {
		setIsOpen(false);
	}
	async function submitEmail() {
		let bodyData: { [name: string]: string } = {}
		let inputEl = document.getElementById('email') as HTMLInputElement
		let inputVal = (inputEl as HTMLInputElement)!.value as string
		inputEl!.value = ''
		bodyData['email'] = inputVal
		const rootUrl = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BACKEND_URL : window.location.origin
		let fetch_link = `${rootUrl}/sendgrid/sign_up`
		let response = await (fetch(fetch_link, {
			method: "POST",
			body: JSON.stringify(bodyData),
			headers: {
				'cache': "no-cache",
				'pragma': "no-cache",
				'Cache-Control': 'no-cache',
				'Content-Type': 'application/json'
			}
		}))
		openModal()
	}

	return (
		<div className="signup-container" id="sign-up">
			<ModalComponent modalIsOpen={[modalIsOpen, setIsOpen]}>
				<>
					<div onClick={() => { closeModal() }} style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<IoMdClose size={30} />
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: 10, padding: 50, justifyContent: 'center', alignItems: 'center', gap: 30 }}>
						<h1>Thank you for registering with Evata!</h1>
						<p style={{ maxWidth: 400 }}>
							One of our team will be in touch shortly with your account registration details
						</p>
					</div>
				</>

			</ModalComponent>
			<h2>Get access to our platform for <span className="white-highlight">FREE</span>!</h2>
			<br />
			<p>Register your email address and we'll get you setup</p>
			<br />
			<br />
			<div>
				<input type="text" className="textbox-homepage" placeholder="Enter your email" name="email" id="email" />
				<button
					className="primary-btn"
					onClick={() => submitEmail()}
				>Submit</button>
			</div>
		</div>
	)
}

export default SignupContainer