import { useEffect, useState } from 'react'
import './HomePage.scss'

//components
import { FaCheck, } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import ChooseCardContainer from '../../components/homepage/HomePageComponent/ChooseCard'
import CopyBlock from '../../components/homepage/HomePageComponent/CopyBlock'
import Footer from '../../components/homepage/HomePageComponent/Footer'
import ImageDisplay from '../../components/homepage/HomePageComponent/ImageDisplay'
import JourneyNav from '../../components/homepage/HomePageComponent/Nav'
import ResultsBlockContainer from '../../components/homepage/HomePageComponent/ResultsBlock'
import ScrollBack from '../../components/homepage/HomePageComponent/ScrollBack'
import SignupContainer from '../../components/homepage/HomePageComponent/SignupContainer'
import VideoComponent from '../../components/homepage/HomePageComponent/VideoComponent'
import infrastructure from '../../static/Preview_images/infrastructure.png'
import platform from '../../static/Preview_images/platform.png'
import tcoPayback from '../../static/Preview_images/tco-payback.png'



const HomePage = () => {
    const [showBigImage, setShowBigImage] = useState(false)
    const [image, setImage] = useState("")
    const [scrollY, setScrollY] = useState(0)

    let tcoPaybackScreen = process.env.NODE_ENV === "production" ? process.env.REACT_APP_CDN_URL + "/static/Preview_images/tco-payback.png" : tcoPayback
    let infrastructureScreen = process.env.NODE_ENV === "production" ? process.env.REACT_APP_CDN_URL + "/static/Preview_images/infrastructure.png" : infrastructure
    let platformScreen = process.env.NODE_ENV === "production" ? process.env.REACT_APP_CDN_URL + "/static/Preview_images/platform.png" : platform



    function logit() {
        setScrollY(window.pageYOffset);
    }


    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", logit);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", logit);
        };
    });

    return (
        <>
            <div style={{ background: "white", width: "100%" }}>
                <JourneyNav fixed="fixed-top" homePage={true} />
                <VideoComponent />
                <div className="journey-container">

                    <CopyBlock image={platformScreen} alignment="copyblock-banner-left" title1="" title2="Share" title3=" your charging infrastructure and get access to the UK's most " title4="economical" title5=" infrastructure charging network." text=" " />

                    {/* <CopyBlock alignment="copyblock-banner-right" title1="Streamline the " title2="Procurement" title3=" Process" text="Evata helps to streamline the multifaceted nature of procurement by offering recommended vehicles from approved sellers. We'll also provide you with an overview of government incentives and grants available to your business, including the Workplace Charging Scheme (WCS)." /> */}
                    <CopyBlock image={infrastructureScreen} alignment="copyblock-banner-right" title1="We can " title2="minimise" title3=" downtime, boost ROI and reduce the operational " title4="cost" title5=" of your electric fleet." text="Evata is a digital infrastructure provider enabling fleet operators to share their charging infrastructure whilst simultaneously providing access to the UKs most economical infrastructure network." />
                    <ResultsBlockContainer />

                    <div className="copyblock-banner-left">
                        <h2><span><span className="hero-highlight">Features</span></span></h2>
                        <div className="sub-text">

                                <div><h3><FaCheck fontSize={24} color="#BEF213"/> Bookable charging infrastructure</h3></div>
                                <div><h3><FaCheck fontSize={24} color="#BEF213" /> B2B only</h3></div>
                                <div><h3><FaCheck fontSize={24} color="#BEF213" /> Site security and operating considerations guaranteed</h3></div>
                                <div><h3><FaCheck fontSize={24} color="#BEF213" /> Unified payments, billing and expense management</h3></div>
                                <div><h3><FaCheck fontSize={24} color="#BEF213" /> Hardware agnostic</h3></div>
                            <div><p></p></div>
                        </div>
                        <img src={image} alt="" style={{ width: '100%', borderRadius: 10, boxShadow: "0px 3px 15px rgb(0 0 0 / 20%)" }} />
                    </div>

                    <ChooseCardContainer />

                    <SignupContainer />
                    <Footer />
                    {showBigImage ? <ImageDisplay img={image} showBigImage={showBigImage} setShowBigImage={setShowBigImage} /> : <></>}
                    {(scrollY > 500) ? <ScrollBack /> : <></>}

                </div>
            </div>
        </>
    )
}

export default HomePage