interface ChooseCardInterface {
	img: string,
	title: string,
	content: string
}

const ChooseCard = (props: ChooseCardInterface) => {


	return (
		<div className="choose-card">
			<div style={{ backgroundImage: `url(${props.img})` }} className="img-container" />
			<div className="content-container">
				<div className="flex-container">
					<h2>{props.title}</h2>
					<p>{props.content}</p>
				</div>
			</div>

		</div>
	)
}

const ChooseCardContainer = () => {
	return (
		<>
			<div className="signup-container">

				<h2>Why choose <span className="white-highlight">Evata</span>?</h2>
				<p>Evata's Electrification Management Software enables fleets to obtain an unfair advantage:</p>
				<div style={{display: 'block'}}>
					<div className="white-highlight2">Increased operational efficiencies</div>
					<div className="white-highlight2">Unparalleled customer experience</div>
					<div className="white-highlight2">Market defining data visibility</div>
				</div>

			</div>
			{/* <div className="choose-card-container">
				<ChooseCard title="Procure" content="Identifies your electric fleet needs in less than 60 seconds, for free." img={`https://evata-dev-4-cdn.evata.co.uk/web/static/icons/cart.png`} />
				<ChooseCard title="Manage" content="Access our fleet infrastructure services such as charging payment systems and procure on demand services, all whilst tracking the cost and carbon impact of your fleet – BSI 14060 compliant." img={`https://evata-dev-4-cdn.evata.co.uk/web/static/icons/switches.png`} />
				<ChooseCard title="Dispose" content="Receive live notifications on your fleet value and access our remarketing service." img={`https://evata-dev-4-cdn.evata.co.uk/web/static/icons/waste.png`} />
			</div> */}
		</>
	)
}


export default ChooseCardContainer