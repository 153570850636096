const ScrollBack = () => {

	const scrollup = () => {
		window.scrollTo(0, 0)
	}

	return (
		<div onClick={scrollup} className="scroll-up-btn">
			<svg className="scroll-up-svg">
				<line x1="10" y1="17.5" x2="15" y2="12.5" strokeWidth="3" stroke="white" strokeLinecap="round"/>
				<line x1="15" y1="12.5" x2="20" y2="17.5" strokeWidth="3" stroke="white" strokeLinecap="round"/>
			</svg>
		</div>
	)
}

export default ScrollBack