
interface ResultsBlockInterface {
	img: string,
	title: string,
	content: string
}

const ResultsBlock = (props: ResultsBlockInterface) => {


	return (
		<div className="choose-card">
			<div style={{ backgroundImage: `url(${props.img})` }} className="img-container" />
			<div className="content-container">

			</div>

		</div>
	)
}

const ResultsBlockContainer = () => {
	return (
		<>
			<div className="signup-container">

				<h2>The Result</h2>
                <p style={{color: '#000000'}}>Combining Evata’s data automation and infrastructure capabilities enables a transformational shift in the Commercial electric vehicle market and we can help you work towards...</p>
				<div style={{display: 'block'}}>
					<div className="white-highlight2"><div><h2>70%</h2></div><div>Reduction in Fleet operational downtime</div></div>
					<div className="white-highlight2"><div><h2>25%</h2></div><div>Lower Total Cost of Operation for Your Fleet!</div></div>
					<div className="white-highlight2"><div><h2>3 years</h2></div><div>Realisation of ROI on DC Infrastructure</div></div>
				</div>

			</div>
		</>
	)
}


export default ResultsBlockContainer